import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import { enIN } from 'date-fns/locale';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Input } from 'reactstrap';
import { formatDate } from 'helpers/helpers';
import { customStyles as select2CustomStyles } from 'helpers/custom_Styles';

/**
 * DatePicker component allows users to select a single date within a specified range.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Date} props.date - Initial date
 * @param {Function} props.onDateChange - Callback function to handle date changes
 * @param {Date} [props.minDate] - Minimum selectable date
 * @param {Date} [props.maxDate] - Maximum selectable date
 * @param {boolean} [props.isSearchable] - Enable/disable search in dropdowns
 * @param {boolean} [props.disabled] - Enable/disable the date picker
 */
const DatePicker = ({ 
    date, 
    onDateChange, 
    minDate = new Date('2023-01-01'), 
    maxDate, 
    isSearchable = true, 
    disabled = false 
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(date ? new Date(date) : null);
    const [displayedDate, setDisplayedDate] = useState(date ? new Date(date) : new Date());
    const ref = useRef(null);
    const calendarRef = useRef(null);
    const [dropUpMode, setDropUpMode] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    /**
     * Handles the date selection from the calendar.
     *
     * @param {Date} date - The selected date
     */
    const handleSelect = (date) => {
        setSelectedDate(date);
        setShowCalendar(false);
        
        if (onDateChange) {
            onDateChange(date);
        }
    };

    /**
     * Handles clicks outside the calendar to close it.
     *
     * @param {Event} event - The click event
     */
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowCalendar(false);
        }
    };

    /**
     * Resets the selected date to null.
     */
    const resetDate = () => {
        if (disabled) return;
        setSelectedDate(null);
        if (onDateChange) {
            onDateChange(null);
        }
    };

    const calculatePosition = () => {
        if (ref.current && calendarRef.current) {
            const parentRect = ref.current.getBoundingClientRect();
            const calendarRect = calendarRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;

            // Check if calendar would go beyond bottom of viewport
            const spaceBelow = viewportHeight - parentRect.bottom;
            const spaceAbove = parentRect.top;
            const dropUp = spaceBelow < calendarRect.height && spaceAbove > (calendarRect.height + 20);
            setDropUpMode(dropUp);

            // Calculate horizontal position
            let leftPosition = 0;
            const rightEdge = parentRect.left + calendarRect.width;
            
            if (rightEdge > viewportWidth) {
                // If calendar would overflow right edge, align to right
                leftPosition = Math.min(0, parentRect.width - calendarRect.width);
            }

            // Update calendar position
            if (calendarRef.current) {
                calendarRef.current.style.left = `${leftPosition}px`;
            }

            setIsVisible(true);
        }
    };

    const handleFocus = () => {
        if (disabled) return;
        setShowCalendar(true);
        // Reset visibility when opening
        setIsVisible(false);
        // Calculate position in next frame
        requestAnimationFrame(() => {
            requestAnimationFrame(calculatePosition);
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (date !== selectedDate) {
            setSelectedDate(date ? new Date(date) : null);
        }
    }, [date]);

    useEffect(() => {
        if (showCalendar) {
            calculatePosition();
            window.addEventListener('scroll', calculatePosition);
            window.addEventListener('resize', calculatePosition);
        }
        
        return () => {
            window.removeEventListener('scroll', calculatePosition);
            window.removeEventListener('resize', calculatePosition);
        };
    }, [showCalendar]);

    return (
        <div style={{ position: 'relative', opacity: disabled ? 0.6 : 1 }} ref={ref}>
            <div className='form-group'>
                <div className="input-group">
                    <Input
                        type="text"
                        placeholder="Select Date"
                        onFocus={handleFocus}
                        value={formatDate(selectedDate) || '-/-/-'}
                        readOnly
                        disabled={disabled}
                    />
                    <div onClick={resetDate} className={`input-group-cross-button me-2 ${disabled ? 'disabled' : ''}`}>
                        <i className="mdi mdi-close" />
                    </div>
                </div>
            </div>
            {showCalendar && (
                <div ref={calendarRef} style={{ 
                    position: 'absolute',
                    ...(dropUpMode 
                        ? { bottom: '100%', marginBottom: '5px' }
                        : { top: '100%', marginTop: '5px' }
                    ),
                    left: 0,
                    zIndex: 999999999999999, 
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    background: 'white',
                    visibility: isVisible ? 'visible' : 'hidden',
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 0.1s',
                    // maxHeight: 500,
                    // overflowY: 'auto'
                }}>
                    <style>
                        {`
                            .rdrDateDisplay { display: none !important; }
                            .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
                                z-index: 0 !important;
                            }
                            .rdrDayToday .rdrDayNumber span:after {
                                background: #bababa !important;
                            }
                        `}
                    </style>
                    <Calendar
                        date={selectedDate}
                        onChange={handleSelect}
                        locale={enIN}
                        color="#bababa"
                        minDate={minDate}
                        maxDate={maxDate}
                        showMonthArrow={true}
                        shownDate={displayedDate}
                        onShownDateChange={(date) => setDisplayedDate(date)}
                        navigatorRenderer={({ date }, changeShownDate) => {
                            const currentDate = date || displayedDate;
                            
                            const goToPreviousMonth = () => {
                                const newDate = new Date(currentDate);
                                newDate.setMonth(currentDate.getMonth() - 1);
                                setDisplayedDate(newDate);
                                changeShownDate(-1, 'monthOffset');
                            };
                            
                            const goToNextMonth = () => {
                                const newDate = new Date(currentDate);
                                newDate.setMonth(currentDate.getMonth() + 1);
                                setDisplayedDate(newDate);
                                changeShownDate(1, 'monthOffset');
                            };

                            const monthOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
                                const isDisabled = (
                                    (minDate && currentDate.getFullYear() === minDate.getFullYear() && month < minDate.getMonth()) ||
                                    (maxDate && currentDate.getFullYear() === maxDate.getFullYear() && month > maxDate.getMonth())
                                );
                                return {
                                    value: month,
                                    label: enIN.localize.month(month),
                                    isDisabled
                                };
                            });

                            const yearOptions = (() => {
                                const startYear = minDate ? minDate.getFullYear() : currentDate.getFullYear() - 10;
                                const endYear = maxDate ? maxDate.getFullYear() : currentDate.getFullYear() + 9;
                                const length = endYear - startYear + 1;
                                
                                return Array.from(
                                    { length }, 
                                    (_, i) => ({
                                        value: startYear + i,
                                        label: String(startYear + i)
                                    })
                                );
                            })();

                            const customStyles = {
                                ...select2CustomStyles()
                            };

                            return (
                                <div className="rdrMonthAndYearPickers" style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: '8px', 
                                    marginBottom: 10,
                                    padding: '0 10px',
                                    width: '100%'
                                }}>
                                    <button 
                                        onClick={goToPreviousMonth}
                                        className="rdrNextPrevButton rdrPprevButton"
                                        type="button"
                                        style={{ marginLeft: 3, flexShrink: 0 }}
                                        disabled={
                                            minDate && 
                                            currentDate.getFullYear() === minDate.getFullYear() && 
                                            currentDate.getMonth() <= minDate.getMonth()
                                        }
                                    >
                                        <i></i>
                                    </button>
                                    <div style={{ display: 'flex', flex: 1, gap: '8px' }}>
                                        <Select
                                            options={monthOptions}
                                            value={monthOptions.find(option => option.value === displayedDate.getMonth())}
                                            onChange={(option) => {
                                                const newDate = new Date(displayedDate);
                                                newDate.setMonth(option.value);
                                                setDisplayedDate(newDate);
                                                changeShownDate(option.value, 'setMonth');
                                            }}
                                            styles={customStyles}
                                            isSearchable={isSearchable}
                                            className="month-select"
                                            isOptionDisabled={(option) => option.isDisabled}
                                        />
                                        <Select
                                            options={yearOptions}
                                            value={yearOptions.find(option => option.value === displayedDate.getFullYear())}
                                            onChange={(option) => {
                                                const newDate = new Date(displayedDate);
                                                newDate.setFullYear(option.value);
                                                setDisplayedDate(newDate);
                                                changeShownDate(option.value, 'setYear');
                                            }}
                                            styles={customStyles}
                                            isSearchable={isSearchable}
                                            className="year-select"
                                            isOptionDisabled={(option) => option.isDisabled}
                                        />
                                    </div>
                                    <button 
                                        onClick={goToNextMonth}
                                        className="rdrNextPrevButton rdrNextButton"
                                        type="button"
                                        style={{ marginRight: 3, flexShrink: 0 }}
                                        disabled={
                                            maxDate && 
                                            currentDate.getFullYear() === maxDate.getFullYear() && 
                                            currentDate.getMonth() >= maxDate.getMonth()
                                        }
                                    >
                                        <i></i>
                                    </button>
                                </div>
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
};

DatePicker.propTypes = {
    date: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    isSearchable: PropTypes.bool,
    disabled: PropTypes.bool
};

export default DatePicker;