export const customStyles = (validationTouched, validationErrors) => ({
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set a white background
    zIndex: 1050,            // Ensure dropdown appears above other elements
    width: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#e9ecef" // Slightly darker grey for selected option
      : state.isFocused
      ? "#f8f9fa" // Light grey for focused option
      : "#fff",   // Default white background
    color: state.isSelected ? "#000" : "#333", // Text color for selected option
    cursor: "pointer",                         // Pointer on hover
    "&:active": {
      backgroundColor: state.isSelected
        ? "#e9ecef" // Maintain color for selected option on click
        : "#f8f9fa", // Maintain focused color on click
    },
    "&:hover": {
      backgroundColor: "#f8f9fa" // Setting hover color explicitly
    },
  }),
  control: (provided) => ({
    ...provided,
    borderColor:
      validationTouched && validationErrors
        ? "#f46a6a"         // Red border on error
        : "#ced4da",    // Normal Bootstrap-like border
    boxShadow: "none",  // Remove focus shadow
    "&:hover": {
      borderColor:
        validationTouched && validationErrors
          ? "#f46a6a" // Keep red border on hover if error
          : "#80bdff", // Optional hover effect for valid control
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6c757d", // Placeholder color
  }),
  container: (provided) => ({
    ...provided,
    flex: 1,
    minWidth: 0
  }),
  menu: (base) => ({
      ...base,
      "& ::-webkit-scrollbar": {
          width: "3px",
          height: "0px",
      },
      "& ::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "2px"
      },
      "& ::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "2px"
      },
      "& ::-webkit-scrollbar-thumb:hover": {
          background: "#555"
      }
  }),
  // singleValue: (provided, state) => ({
  //   ...provided,
  //   color: state.data.color,
  // }),
});

export default customStyles;
