import React from "react";

// import apexChart
import LineApexChart from "../../AllCharts/apex/chartapex"
import DashedLine from "../../AllCharts/apex/dashedLine"
import SplineArea from "../../AllCharts/apex/SplineArea"
import Apaexlinecolumn from "../../AllCharts/apex/apaexlinecolumn"
import ColumnWithDataLabels from "../../AllCharts/apex/ColumnWithDataLabels"
import BarChart from "../../AllCharts/apex/barchart"
import LineColumnArea from "../../AllCharts/apex/LineColumnArea"
import RadialChart from "../../AllCharts/apex/RadialChart"
import PieChart from "../../AllCharts/apex/PieChart"
import DonutChart from "../../AllCharts/apex/dountchart";
import DumbelColumnChart from "../../AllCharts/apex/DumbelColumn";
import RangeAreaChart from "../../AllCharts/apex/RangeArea";
import DumbelHorizontalChart from "../../AllCharts/apex/DumbellHorizontal";
import FunnelChart from "../../AllCharts/apex/FunnelChart";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Apexchart1 = () => {

  //meta title
  // document.title="Apex Charts | Skote - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Charts" breadcrumbItem="Apex Charts" /> */}

         
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"> Column Chart </CardTitle>
                  <Apaexlinecolumn dataColors='["--bs-danger","--bs-primary", "--bs-success"]'/>
                </CardBody>
              </Card>
            </Col>
          </Row>

      
       



        
        </div>
      </div>
    </React.Fragment>
  )
}

export default Apexchart1
