import React, { useState } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"

// Import the images
import Order1 from "../../assets/images/procurement/Procurement-from order.jpg"
import Order2 from "../../assets/images/procurement/Procurement-from order 2.jpg"

const FromOrder = () => {
  // Meta title
  document.title = `Ready Item |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  // State to manage the current image
  const [currentImage, setCurrentImage] = useState(Order1)

  // Toggle function to switch images
  const toggleImage = () => {
    setCurrentImage(prevImage => (prevImage === Order1 ? Order2 : Order1))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Procurement" breadcrumbItem="From Order" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1">
                      {"From Order"}
                    </h4>
                  </div>
                </CardBody>
                <CardBody>
                  <div>
                    {/* Display the image */}
                    <img
                      src={currentImage}
                      alt="Procurement-List"
                      className="img-fluid"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        cursor: "pointer", // Add pointer cursor to indicate clickability
                      }}
                      onClick={toggleImage} // Toggle image on click
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default FromOrder
