import { del, get, patch, post } from "helpers/api_helper";

export const getClientList = async(params) => await get('/client',{params});

export const getClientType = async (params) => await get('/client-type',{params})

export const getPaymentTerm = async (params) => await get('/payment-term',{params})

export const postClient = async (payload) => await post('/client',payload);

export const getClient = async (id) => await get(`/client/${id}`);

export const updateClient = async (id,payload) => await patch(`/client/${id}`,payload);

export const deleteClient = async (id) =>await del(`/client/${id}`);