import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
//import components

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  CardTitle,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Table,
  Fade,
  Label,
  FormFeedback,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  deleteClient,
  getClientList,
  getClientType,
} from "services/ClientManagementServices"
import Paginations from "components/Common/Pagination"
import moment from "moment"
import { isEmpty } from "lodash"
import SearchPage from "components/Common/SearchPage"
import { objectCreateAttributeHelper } from "helpers/globalHelper"
import customStyles from "helpers/custom_Styles"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import Breadcrumb from "components/Common/Breadcrumb"

const Reports = () => {
  //meta title
  document.title = `Report |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  const dummyOrderIds = [
    { value: "ORD123", label: "ORD123" },
    { value: "ORD124", label: "ORD124" },
    { value: "ORD125", label: "ORD125" },
  ]

  const dummyAcYears = [
    { value: "2024", label: "2024-25" },
    { value: "2025", label: "2025-26" },
  ]

  const dummySalesmen = [
    { value: "John Doe", label: "John Doe" },
    { value: "Jane Smith", label: "Jane Smith" },
    { value: "Chris Johnson", label: "Chris Johnson" },
  ]

  const genderOptions = [
    // { value: "", label: "Select" },
    { value: "7", label: "7 Days" },
    { value: "30", label: "30 Days" },
    { value: "90", label: "90 Days" },
    { value: "180", label: "6 Months" },
  ]
  const [clients, setClients] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [job, setJob] = useState(null)
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState([])

  const [searchFilter, setSearchFilter] = useState([])
  const [searchFilter1, setSearchFilter1] = useState([])
  const [clientTypeList, setClientTypeList] = useState([])
  const [isAdvanceSearchOpen, setIsAdvanceSearchOpen] = useState(true)
  const [advanceSearch, setAdvanceSearch] = useState({
    search: "",
    clientTypeId: null,
    orderId: null,
    acYear: "2024",
    salesman: null,
    period: null,
  })

  const formatAdvanceSearchData = () => ({
    search: advanceSearch.search ? advanceSearch.search.trim() : null,
    whatsapp_number: advanceSearch.whatsapp_number
      ? advanceSearch.whatsapp_number.trim()
      : null,
    phone_number: advanceSearch.phone_number
      ? advanceSearch.phone_number.trim()
      : null,
    clientTypeId: advanceSearch.clientTypeId || null,
    orderId: advanceSearch.orderId || null,
    acYear: advanceSearch.acYear || null,
    salesman: advanceSearch.salesman || null,
    period: advanceSearch.period || null,
  })
  const onHandleSearchAdvance = () => {
    console.log("Advance Search Details:", formatAdvanceSearchData())
    setPage(1)
    getClientListRecord(true, false)
  }

  useEffect(() => {
    getClientListRecord()
  }, [limit, page])

  useEffect(() => {
    getCientTypeRecord()
  }, [])

  const getClientListRecord = async (
    advanceSearch = false,
    resetAdvanceSearch = false
  ) => {
    try {
      setLoading(true)

      if (resetAdvanceSearch)
        setAdvanceSearch({
          search: "",
          clientTypeId: null,
          orderId: null,
          acYear: null,
          salesman: null,
          period: null,
          date: null,
        })

      let params = {
        page: page,
        limit: limit,
        // search: search ?? ''
        sort: "client_name",
        sortDirection: "asc",
      }

      if (advanceSearch) {
        console.log("calling")
        const advanceSearchParams = formatAdvanceSearchData()
        params = {
          ...params,
          ...advanceSearchParams,
        }
      }

      // if (isAdvanceSearchOpen && reset!=='reset') {
      //     let advanceKey = objectCreateAttributeHelper(searchFilter)
      //     params = { ...params, ...advanceKey }
      // }

      await getClientList(params)
        .then(response => {
          console.log(response)

          if (response.data) {
            const { meta, data } = response
            setClients(data ?? [])
            // setTotalCount(Math.ceil(meta?.itemCount / meta?.limit));
            setTotalCount(meta?.itemCount)
          }
          setLoading(false)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error, "error")
      setLoading(false)
    }
  }

  const getCientTypeRecord = async () => {
    try {
      let params = {
        status: "active",
      }
      await getClientType(params)
        .then(response => {
          console.log(response)
          // setClientTypeRecordList(response)

          let tempData = response

          setClientTypeList(
            tempData.map(item => ({ value: item.id, label: item.type_name })) ??
              []
          )

          setSearchFilter([
            ...initialFilter,
            {
              label: "Client Type",
              value: "",
              placeholder: "Search Client Name",
              type: "select",
              name: "clientTypeId",
              options: tempData.map(item => ({
                value: item.id,
                label: item.type_name,
              })),
              // disable: false
            },
          ])
          setSearchFilter1([
            ...initialFilter,
            {
              label: "Client Type",
              value: "",
              placeholder: "Search Client Name",
              type: "select",
              name: "clientTypeId",
              options: tempData.map(item => ({
                value: item.id,
                label: item.type_name,
              })),
              // disable: false
            },
          ])
        })
        .catch(err => console.log(err))
    } catch (error) {}
  }

  const onhandleSearchAdvance = () => {
    console.log(searchFilter, "searchFilter")
    setPage(1)

    getClientListRecord()
  }

  const onHandleResetAdvance = () => {
    // setSearchFilter(initialFilter);
    // getCientTypeRecord()
    setSearchFilter(searchFilter1)
    setPage(1)
    getClientListRecord("reset")
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setJob(job)
    setDeleteModal(true)
  }

  const handleDeletejob = async () => {
    if (job && job.id) {
      try {
        await deleteClient(job.id)
          .then(response => {
            console.log(response)
            // if(response){
            toast.success("Client is deleted successfully.", { autoClose: 500 })
            // }
            getClientListRecord()
          })
          .catch(err => console.log(err))
      } catch (error) {
        toast.error("Something went wrong.", { autoClose: 500 })
      }

      setDeleteModal(false)
    }
  }

  const handleSearch = () => {
    setPage(1)
    // getClientListRecord(search)
    console.log("Advance Search Data:", advanceSearch)
  }

  const handleModal = data => {
    setModal(true)
    let temp = data
    setSelectedItem(temp)
  }

  const toggle = () => setModal(!modal)

  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "client_name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Date",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Order Info",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Gst",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Paid",
        enableColumnFilter: false,
        enableSorting: false,
        accessorKey: "clientType.type_name",
      },
      {
        header: "Due",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return <span></span>
        },
      },
      {
        header: "Expected On",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return (
            <span>
              {cellProps.row.original.clientContact?.[0]?.whatsapp_number ?? ""}
            </span>
          )
        },
      },
      {
        header: "Salesman",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const totalOrders = cellProps.row.original.total_order
          const clientId = cellProps.row.original.id // Get the client ID

          // If there is no totalOrders (null or undefined), display '0' without link
          if (totalOrders === null || totalOrders === undefined) {
            return (
              <span
                style={{
                  textAlign: "right",
                  display: "inline-block",
                  width: "90%",
                }}
              >
                <div style={{ marginRight: "15px" }}>0</div>
              </span>
            )
          }

          return (
            <span
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "90%",
              }}
            >
              <div style={{ marginRight: "15px" }}>
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault() // Prevent default navigation
                    window.open(
                      `/orders?adv_client_id=${clientId}`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }}
                >
                  <div>{totalOrders}</div>
                </Link>
              </div>
            </span>
          )
        },
      },
    ],
    [page]
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this client?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Manage Reports" />
          {
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom1">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Sales-Report
                      </h5>
                      <div className="flex-shrink-0">
                        <Button
                          type="secondary"
                          onClick={() => {
                            if (advanceSearch.open) {
                              setPage(1)
                              getClientListRecord(false, true)
                            } else {
                              setAdvanceSearch({
                                ...advanceSearch,
                                open: advanceSearch.open ? false : true,
                              })
                            }
                          }}
                          id={`client-gloabal-search`}
                        >
                          <i className="mdi mdi-magnify" />

                          <UncontrolledTooltip
                            placement="top"
                            target={`client-gloabal-search`}
                          >
                            Search
                          </UncontrolledTooltip>
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    {/* Global Search */}
                    <Fade>
                      {advanceSearch.open && (
                        <Row>
                          <Col lg={3} className="mb-3">
                            <label>Client</label>
                            <Select
                              options={clientTypeList} // Pass the clientTypeList with { label, value } structure
                              value={clientTypeList.find(
                                option =>
                                  option.value === advanceSearch.clientTypeId
                              )} // Set selected value
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  clientTypeId: selectedOption?.value,
                                })
                              } // Update state on change
                              styles={customStyles(
                                false,
                                !advanceSearch.clientTypeId
                              )} // Use customStyles for consistent appearance
                              placeholder="Select" // Placeholder text
                              classNamePrefix="react-select" // For scoping styles
                              isSearchable={true} // Enable search functionality
                            />
                          </Col>
                          <Col lg={3} className="mb-3">
                            <label>Date</label>
                            <input
                              type="text"
                              onChange={e =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  search: e.target.value,
                                })
                              }
                              className="form-control"
                              value={advanceSearch.search}
                            />
                          </Col>
                          <Col lg={3} className="mb-3">
                            <Label>Order Id</Label>
                            <Select
                              options={dummyOrderIds}
                              value={dummyOrderIds.find(
                                option => option.value === advanceSearch.orderId
                              )}
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  orderId: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            />
                          </Col>
                          <Col lg={3} className="mb-3">
                            <Label>AC Year</Label>
                            <Select
                              options={dummyAcYears}
                              value={dummyAcYears.find(
                                option => option.value === advanceSearch.acYear
                              )}
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  acYear: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            />
                          </Col>
                          <Col lg={3} className="mb-3">
                            <Label>Salesman</Label>
                            <Select
                              options={dummySalesmen}
                              value={dummySalesmen.find(
                                option =>
                                  option.value === advanceSearch.salesman
                              )}
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  salesman: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            />
                          </Col>
                          <Col lg={3} className="mb-3">
                            <Label>Period</Label>
                            <Select
                              options={genderOptions}
                              value={genderOptions.find(
                                option => option.value === advanceSearch.period
                              )}
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  period: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            />
                          </Col>
                          <Col
                            lg={6}
                            className="mb-3 d-flex align-items-end justify-content-end"
                          >
                            <Button
                              color="danger"
                              outline
                              className="me-1"
                              onClick={e => {
                                setPage(1)
                                getClientListRecord(false, true)
                              }}
                              id={`client-cancel`}
                            >
                              <i className="mdi mdi-close" />

                              <UncontrolledTooltip
                                placement="top"
                                target={`client-cancel`}
                              >
                                Cancel
                              </UncontrolledTooltip>
                            </Button>
                            <Button
                              color="primary"
                              onClick={e => {
                                setPage(1)
                                handleSearch()
                                // getClientListRecord(true, false)
                              }}
                            >
                              <i className="mdi mdi-cloud-search" /> Search
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Fade>
                    <Row className="mb-2"></Row>
                    <TableContainer
                      columns={columns}
                      data={[]}
                      loading={loading}
                      setLoading={setLoading}
                      isGlobalFilter={false}
                      // isJobListGlobalFilter={true}
                      customPageSize={limit}
                      isPagination={false}
                      SearchPlaceholder="Search for ..."
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                    />

                    {totalCount > 0 && (
                      <Paginations
                        perPageData={limit}
                        // data={clients}
                        data={[]}
                        currentPage={page}
                        setCurrentPage={setPage}
                        isShowingPageLength={!loading}
                        totalCount={totalCount}
                        isLimit={true}
                        limit={limit}
                        setLimit={setLimit}
                        paginationClass="pagination"
                        paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded server"
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Contact Details{" "}
          {!isEmpty(selectedItem) ? ` of ${selectedItem?.client_name}` : ""}
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive"></div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Reports
