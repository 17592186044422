import DeleteModal from "components/Common/DeleteModal"
import React, { useEffect, useMemo, useState } from "react"
// import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Link, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"

import "react-toastify/dist/ReactToastify.css"
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  Label,
  Fade,
  Input,
  UncontrolledTooltip,
} from "reactstrap"
import {
  getSingleUser,
  getUsers,
  singleUserDelete,
} from "services/UserServices"
import TableContainer from "components/Common/TableContainer"
import Paginations from "components/Common/Pagination"
import Breadcrumb from "components/Common/Breadcrumb"
import { deleteCollection, getCollectionList } from "services/MasterServices"

const advanceSearchDefaultData = {
  open: false,
  name: null,
  email: null,
  primary_ph_no: null,
}

const ProCollection = () => {
  document.title = `Collection List | ${process.env.REACT_APP_SITE_NAME} - Admin Panel`
  const [viewSingleUser, setViewSingleUser] = useState([])
  const [deduction, setDeduction] = useState(null)

  const [users, setUsers] = useState([]) // Initialize with an empty array
  const [filteredUsers, setFilteredUsers] = useState([]) // State for filtered users
  const [deleteModal, setDeleteModal] = useState(false)
  const [user, setUser] = useState(null)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  const [modal_center, setModalCenter] = useState(false) // Modal state
  const [selectedUser, setSelectedUser] = useState(null) // Selected user state
  const [loading, setLoading] = useState()
  const [load, setLoad] = useState()
  const [advanceSearch, setAdvanceSearch] = useState(advanceSearchDefaultData)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  //   const onClickDelete = id => {
  //     console.log("Data delete id-", id)
  //     setUser(id)
  //     setDeleteModal(true)
  //   }

  const handleDeleteUser = async () => {
    console.log("Delete Now-", user)
    // if (user && user.id) {
    //   try {
    //     const response = await singleUserDelete(user.id) // Pass the user ID
    //     // alert("Deleted")
    //     console.log("User deleted successfully:", response)

    //     toast.success("User Deleted successfully!")

    //     setDeleteModal(false)
    //     setUser(null)
    //     fetchAllUsers()
    //   } catch (error) {
    //     console.error("Error deleting user:", error.message)
    //     toast.error("Failed to delete user.") // Notify error
    //   }
    // }
  }

  //   const handleSearch = () => {
  //     const searchTerm = search.trim().toLowerCase()

  //     setFilteredUsers(
  //       !searchTerm
  //         ? users // Reset if search is empty
  //         : users.filter(
  //             user =>
  //               `${user.first_name || ""} ${user.middle_name || ""} ${
  //                 user.last_name || ""
  //               }`
  //                 .toLowerCase()
  //                 .includes(searchTerm) ||
  //               (user.email || "").toLowerCase().includes(searchTerm) ||
  //               (user.primary_ph_no || "").includes(searchTerm) ||
  //               (user.whatsapp_no || "").includes(searchTerm) || // Handle null/undefined whatsapp_no
  //               (user.gender || "").toLowerCase().includes(searchTerm) ||
  //               (user.address || "").toLowerCase().includes(searchTerm)
  //           )
  //     )
  //   }
  // const handleSearch = () => {
  //   if (search.trim() === "") {
  //     setFilteredUsers(users) // Reset to original user list if search is empty
  //   } else {
  //     const searchTerm = search.toLowerCase()
  //     const filtered = users.filter(
  //       user =>
  //         `${user.first_name} ${user.middle_name} ${user.last_name}`
  //           .toLowerCase()
  //           .includes(searchTerm) ||
  //         user.email.toLowerCase().includes(searchTerm) ||
  //         user.primary_ph_no.includes(searchTerm) ||
  //         (user.whatsapp_no && user.whatsapp_no.includes(searchTerm)) || // Check if whatsapp_no is not null before searching
  //         user.gender.toLowerCase().includes(searchTerm) ||
  //         user.address.toLowerCase().includes(searchTerm)
  //     )
  //     setFilteredUsers(filtered)
  //   }
  // }
  const handleDeleteCategory = async () => {
    if (deduction && deduction.id) {
      try {
        await deleteCollection(deduction.id)
          .then(response => {
            console.log(response, "response")
            getList()
            setDeleteModal(false)
            toast.success(`Collection is Deleted Successfully`, {
              autoClose: 2000,
            })
          })
          .catch(err => {
            console.log(err)

            toast.error(`Collection is Deleted Failed`, {
              autoClose: 2000,
            })
          })
      } catch (error) {
        toast.error(` Deduction is Deleted Failed`, { autoClose: 2000 })
      }
    }
  }
  const handleModal = data => {
    setModal(true)
    // setSelectedItem(data)
  }
  const handleView = user => {
    console.log("View Clicked --", user)
    setSelectedUser(user) // Set selected user for viewing
    setModalCenter(true) // Open modal
    fetchSingleUserData(user)
  }
  const onClickDelete = deduction => {
    setDeduction(deduction)
    setDeleteModal(true)
  }
  const tog_center = () => {
    setModalCenter(!modal_center) // Toggle modal state
  }

  const closeModal = () => {
    setModalCenter(false) // Close modal
    setSelectedUser(null) // Clear selected user after closing modal
    setViewSingleUser([])
  }

  //   const fetchSingleUserData = async user => {
  //     console.log("User if fetchsingleuser", user)
  //     setLoad(true)
  //     try {
  //       await getSingleUser(user)
  //         .then(response => {
  //           console.log("Response Data Single User:", response)
  //           setViewSingleUser(response)
  //           setLoad(false)
  //         })
  //         .catch(err => {
  //           console.log(err)
  //           setLoad(false)
  //         })
  //     } catch (error) {
  //       console.error("Error fetching user data:", error.message)
  //       setLoad(false)
  //     }
  //   }
  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <Link to="" className="text-body fw-bold">
              {cellProps.row.index + 1}
            </Link>
          )
        },
      },
      {
        header: "Collection",
        accessorKey: "collection_name",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        header: () => <div style={{ textAlign: "center" }}>Total Products</div>,
        accessorKey: "total_prod_count",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => (
          <div style={{ textAlign: "center" }}>
            {cellProps.row.original.total_prod_count}
          </div>
        ),
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return cellProps.row.original.start_date
            ? moment(cellProps.row.original.start_date).format("DD-MM-YYYY")
            : "-"
        },
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return cellProps.row.original.end_date
            ? moment(cellProps.row.original.end_date).format("DD-MM-YYYY")
            : "-"
        },
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          switch (cellProps.row.original.status) {
            case 1:
              return <span className="badge badge-soft-success">Active</span>
            case 0:
              return <span className="badge badge-soft-danger">Inactive</span>
          }
        },
      },
      {
        header: () => <div style={{ textAlign: "right" }}>Action</div>,
        id: "deduction-action",
        enableColumnFilter: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
              <li>
                <Link
                  to="/editCollection"
                  className="btn btn-sm btn-soft-info"
                  state={{collectionId:cellProps.row.original.id}}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to=""
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  useEffect(() => {
    getList()
  }, [navigate, page, limit])

  const getList = async () => {
    try {
      let params = {
        // parent_type: "parent",
      }

      setLoading(true)

      await getCollectionList({ params })
        .then(response => {
          console.log("get List", response)

          if (response) {
            let arr = response

            if (arr.find(item => item.id === 1)) {
              // Remove the first element and store it
              const firstElement = arr.shift()

              // Add the removed element to the end of the array
              // arr.push(firstElement);
            }

            setFilteredUsers(arr)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)

          setLoading(false)
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const handleAddClick = () => {
    // Remove collectionId and collectionName from localStorage
    localStorage.removeItem("collectionId");
  
 };
  return (
    <React.Fragment>
      <Modal isOpen={modal_center} toggle={tog_center} centered>
        <div className="modal-header">
          <h3 className="modal-title mt-0">
            <strong>View User Details</strong>
          </h3>
          <button
            type="button"
            onClick={closeModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {load ? (
            "Loading "
          ) : //After loading the data checks for data if data present the show it else show no user data found
          viewSingleUser ? (
            <div>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="name">
                      <strong>Collection Name:&nbsp;</strong>
                    </Label>

                    {`${viewSingleUser?.first_name} ${viewSingleUser?.middle_name} ${viewSingleUser?.last_name}`}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="email">
                      <strong>Email:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.email}{" "}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="gender">
                      <strong>Gender:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.gender
                      ? viewSingleUser.gender.charAt(0).toUpperCase() +
                        viewSingleUser.gender.slice(1).toLowerCase()
                      : "Not specified"}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="phone1">
                      <strong>Primary Phone Number:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.primary_ph_no}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="phone2">
                      Additional Phone Number:&nbsp;
                    </Label>
                    {viewSingleUser?.addl_ph_no}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="pan">
                      <strong>Pan Card No:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.pan_no}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="adhar">
                      <strong>Adhar Card No:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.aadhar_no}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="add">
                      <strong>Address:&nbsp;</strong>
                    </Label>
                    {viewSingleUser?.address}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="status">
                      <strong>Status:&nbsp;</strong>
                    </Label>

                    {viewSingleUser?.status === 1 ? (
                      <span className="badge badge-soft-success">Active</span>
                    ) : (
                      <></>
                    )}

                    {viewSingleUser?.status === 0 ? (
                      <span className="badge badge-soft-danger">In-Active</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p>No user selected.</p>
          )}
        </div>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {
          setUser(null)
          setDeleteModal(false)
        }}
        mainHeader="Are you sure you want to delete this user?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Manage Collections" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom1">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 flex-grow-1">Collections</h4>
                    <div className="flex-shrink-0">
                      <Link
                        to="/addCollection"
                        className="btn btn-primary me-1 same-search-btn add"
                        onClick={handleAddClick}
                      >
                        <i className="mdi mdi-plus" />
                        Add
                      </Link>
                      {/* <Button
                        type="secondary"
                        id="user-search"
                        onClick={() => {
                          if (advanceSearch.open) {
                            setPage(1)
                            fetchAllUsers(false, true)
                          } else {
                            setAdvanceSearch({
                              ...advanceSearch,
                              open: advanceSearch.open ? false : true,
                            })
                          }
                        }}
                      >
                        <i className="mdi mdi-magnify" />
                        <UncontrolledTooltip target={`user-search`}>
                          Search
                        </UncontrolledTooltip>
                      </Button> */}
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  {/* <Row className="mb-2">
                    <Col sm={4}>
                      <input
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value)
                          handleSearch(e.target.value) // Call handleSearch on input change
                        }}
                        type="text"
                        placeholder="Keyword Search"
                        className="form-control search-box me-2 mb-2 d-inline-block"
                      />
                    </Col>
            
                  </Row> */}

                  <TableContainer
                    loading={loading}
                    setLoading={setLoading}
                    columns={columns}
                    data={filteredUsers} // Show all filtered users
                    isPagination={false}
                    // itemsPerPage={10}
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />

                  {totalCount > 0 && (
                    <Paginations
                      perPageData={limit}
                      data={filteredUsers}
                      currentPage={page}
                      setCurrentPage={setPage}
                      isShowingPageLength={!loading}
                      totalCount={totalCount}
                      paginationClass="pagination"
                      paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer position="top-right" autoClose={1500} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCategory}
          onCloseClick={() => setDeleteModal(false)}
          mainHeader="Are you sure you want to delete this deduction Reason?"
        />
      </div>
    </React.Fragment>
  )
}

export default ProCollection
